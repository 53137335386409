import { Model } from '../../Model';
import { MetaController } from '../../controllers/meta';
import { SchemaController } from '../../controllers/schema';
import { UserController } from '../user';
import { ItemController } from '../item';
import Fuse from 'fuse.js'

import { CollectionView } from './collection.view';

export const CollectionController = {
    init: function(schemaName) {
        var collectionSchema = SchemaController.get(schemaName);
        SchemaController.setActiveSchema(collectionSchema);

        //window.location.href = 'collection/'+schemaName;
        //window.history.replaceState({}, schemaName, 'collection/'+schemaName)
        // history.pushState({
        //     schema: collectionSchema
        // }, 'Collection', '/col/'+schemaName);

        $('.dashboard').replaceWith(CollectionView.dom());
        Model.collectionSearchQuery = undefined;

        CollectionController.load(SchemaController.getActiveSchema(), function() {
            console.log('Collection loaded: '+schemaName);
        });
        
        // Init the Fuse instance
        const schema = SchemaController.getActiveSchema();
        const searchKeys = [schema.itemPath, ...(schema.searchKeys || [])]

        // Init fuse with an empty array, until we get results back
        Model.collectionFuseInstance = new Fuse([] , {
            // TODO use key from schema.itemPath
            keys: searchKeys,
            threshold: .3
        })
    },
    load: function(schema, callback) {
        if (!schema) {
            schema = SchemaController.getActiveSchema();
        }

        if (!callback) {
            callback = function() {};
        }

        if (Model.collectionSearchQuery) $('.search-input').val(Model.collectionSearchQuery);
        
        const serializeFilters = filters => {
            const newFilters = {};
            
            for (const key in filters) {
                const values = [...filters[key].values()];
                newFilters[key] = values;
            }
            
            return newFilters;
        };

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/collection/load',
            data: {
                schemaName: schema.schemaName,
                collectionName: schema.collectionName,
                activeFields: [...Model.activeSchemaColumns.keys()],
                activeFilters: serializeFilters(Model.activeSchemaFilters),
                activeSort: Model.activeSchemaSort
            },
            beforeSend: function(xhr) {
                console.log('beforeSend', xhr);
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                Model.activeCollection = data;
                $('.breadcrumbs').text('Components / '+schema.displayName);
                $('.collection-name').text(schema.displayName);
                $('.record-count').text(data.records.length);
                $('.table-header')
                    .html(CollectionView.headers(Model.activeSchemaColumns))
                    .sortable({
                        axis: 'x',
                        //connectWith: '.record',
                        containment: 'parent',
                        tolerance: 'pointer',
                        cursor: 'ew-resize',
                        start: function(e, ui) {
                            ui.item.data('originIndex', ui.item.index());
                        },
                        stop: function(e, ui) {
                            const columns = MetaController.arrayMove([...Model.activeSchemaColumns], ui.item.data('originIndex'), ui.item.index());
                            Model.activeSchemaColumns = new Map(columns);
                            
                            CollectionController.load();
                        }
                    });


                $('.records-container')
                    .html('')
                    .scrollTop(0)
                    .off('scroll')
                    .on('scroll', function() {
                        if ($(this).scrollTop() + $(this).innerHeight() >= ($(this)[0].scrollHeight) - 50) {
                            CollectionController.loadChunk();
                        }
                    });
                    // Setting Fuse collection 
                    Model.collectionFuseInstance.setCollection(Model.activeCollection.records);
                    CollectionController.refreshList();

                callback();
            },
            error: function() {
                //localStorage.token

                UserController.logout();
            }
        });
    },
    // Split out function for list refresh
    refreshList: function() {
        let searchQuery = Model.collectionSearchQuery ;
        
        if ( searchQuery ) {

            let filteredRecords = Model.collectionFuseInstance.search(searchQuery);
            // Assign filtered records back into the global records
            Model.activeCollection.filteredRecords = filteredRecords.map(filteredRecord => filteredRecord.item);
            
        } else {
            Model.activeCollection.filteredRecords = [...Model.activeCollection.records]
        }
        $('.records-container')
        .html('')
        .scrollTop(0)

        $('.record-count').text(Model.activeCollection.filteredRecords.length); // Needs run on list refresh

        CollectionController.setChunkLast(0); // Needs run on list refresh
        CollectionController.loadChunk(); // Needs run on list refresh

 

    },

    searchCollection: function(input) {
        // Store the search input globally
        Model.collectionSearchQuery = input;
        // Run a function to refresh list
        CollectionController.refreshList();
    },

    loadChunk: function() {
        var chunkStart = 0;
        var chunkEnd = 100;

        chunkStart = CollectionController.getChunkLast();
        chunkEnd = chunkStart+CollectionController.getChunkSize();

        // console.log('chunkStart: '+chunkStart);
        // console.log('chunkEnd: '+chunkEnd);

        $('.records-container').append(
            CollectionView.records(Model.activeSchemaColumns, Model.activeCollection.filteredRecords.slice(chunkStart, chunkEnd))
        );

        CollectionController.setChunkLast(chunkEnd);
    },
    getChunkSize: function() {
        return Model.collectionChunkSize;
    },
    setChunkLast: function(index) {
        Model.chunkLast = index;
    },
    getChunkLast: function() {
        return Model.chunkLast;
    },
    downloadCSV: function(schema, callback) {
        if (!schema) {
            schema = SchemaController.getActiveSchema();
        }

        if (!callback) {
            callback = function() {};
        }

        // console.log(schema);
        
        const serializeFilters = filters => {
            const newFilters = {};
            
            for (const key in filters) {
                const values = [...filters[key].values()];
                newFilters[key] = values;
            }
            
            return newFilters;
        };

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/collection/download-csv',
            data: {
                schemaName: schema.schemaName,
                activeFields: [...Model.activeSchemaColumns.keys()],
                activeFilters: serializeFilters(Model.activeSchemaFilters),
                activeSort: Model.activeSchemaSort
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            processData: false,
            success: function(data) {
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(new Blob([data], { type: 'text/csv;charset=utf-8;' }), schema.collectionName+'s.csv');
                } else {
                    var element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
                    element.setAttribute('download', schema.collectionName+'s.csv');

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                }

                callback();
            },
            error: function() {
                //alert('Sorry, you are not logged in.');
                window.location = window.location;
            }
        });
    },
    get: function() {
        return Model.activeCollection;
    },
    fieldsSelector: function() {
        $('.side-window').remove();

        $('.collection').append(
            CollectionView.fieldsSelect()
        );
    },
    filtersSelector: function() {
        $('.side-window').remove();

        $('.collection').append(
            CollectionView.filtersSelector()
        );
    },
    globalFindReplace: function(find, replace, collection, path, type) {
        console.log('find: |'+find+'|');
        console.log('replace: |'+replace+'|');
        console.log('collection: '+collection);
        console.log('path: '+path);
        console.log('type: '+type);

        $.ajax({
            type: 'POST',
            url: Model.serverDomain+'/collection/findReplace',
            data: {
                find: find,
                replace: replace,
                collection: collection,
                path: path,
                type: type
            },
            beforeSend: function(xhr) {
                if (localStorage.token) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                }
            },
            success: function(data) {
                ItemController.load(data.insertedDoc.insertedId, function() {

                });
            }
        });
    }
}