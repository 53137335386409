import { MetaController } from '../../controllers/meta';
import { UserController } from './user.controller';
import { Model } from '../../Model';

export const UserView = {
    login: function() {
        // get title if there is one from the url
        const title = MetaController.getUrlParam('title');
        return $('<div/>')
            .addClass('login')
            .append(
                $('<div/>')
                    .addClass('stripe')
                    .append(
                        $('<div/>')
                            .addClass('title')
                            .text(title || MetaController.getSystemTitle())
                    )
                    .append(
                        $('<div/>')
                            .addClass('fields')
                            .append(
                                $('<p class="error-message">')
                                    .hide()
                            )
                            .append(
                                $('<input/>')
                                    .addClass('email')
                                    .attr('type', 'email')
                                    .attr('placeholder', 'Email')
                            )
                            .append(
                                $('<input/>')
                                    .addClass('password')
                                    .attr('type', 'password')
                                    .attr('placeholder', 'Password')
                                    .on('keyup', function(e) {
                                        if (e.keyCode == 13) {
                                            $('.login-btn').trigger('click');
                                        }
                                    })
                            )
                            .append(
                                $('<div/>')
                                .addClass("btn-group")
                                .append(
                                    $('<div/>')
                                        .addClass('login-btn')
                                        .addClass('btn')
                                        .append($("<span/>")
                                            .addClass("submit-text")
                                            .addClass("active")
                                            .text("Sign In")
                                        )
                                        .on('click', function() {
                                            // attempting to signin - activate loader
                                            $(".loader").addClass("active");
                                            $(".submit-text").removeClass("active");
                                            const email = $('input.email').val();

                                            UserController.login(email, $('input.password').val())
                                                .catch(error => {
                                                    let message = error.message;
                                                    const daimlerRe = /@daimler.com$/i;
                                                    
                                                    if (daimlerRe.test(email)) {
                                                        message += ' Try logging in with your daimlertruck.com email address.'
                                                    }
                                                    
                                                    $('.error-message')
                                                        .text(message)
                                                        .slideDown(200);
                                                    
                                                    $('.login .password').val('').focus();
                                                    $(".loader").removeClass("active");
                                                    $(".submit-text").addClass("active");
                                                });
                                        })
                                        .prepend($("<span/>")
                                            .addClass("loader")
                                        )
                                )
                                .append(
                                    $('<div/>')
                                        .addClass('forgot-btn')
                                        .addClass('btn')
                                        .addClass('black')
                                        .text('Forgot Password')
                                        .on('click', function() {
                                            $("body").html(UserView.forgotPassword());
                                        })
                                )
                            )
                    )
            );
    },
    forgotPassword: function() {
        return $('<div/>')
            .addClass('forgot-password')
            .addClass('login')
            .append(
                $('<div/>')
                    .addClass('stripe')
                    .append(
                        $('<div/>')
                            .addClass('title')
                            .text(MetaController.getSystemTitle())
                    )
                    .append(
                        $('<div/>')
                            .addClass('fields')
                            .append(
                                $('<p class="error-message">')
                                    .hide()
                            )
                            .append(
                                $('<p class="success-message">')
                                    .hide()
                            )
                            .append(
                                $('<input/>')
                                    .addClass('email')
                                    .attr('type', 'email')
                                    .attr('placeholder', 'Email')
                            )
                            .append(
                                $('<div/>')
                                .addClass("btn-group")
                                .append(
                                    $('<div/>')
                                        .addClass('submit-btn')
                                        .addClass('btn')
                                        .text('Send Reset Password Email')
                                        .on('click', async () => {
                                            const email = $('input.email').val();
                                            // create application link with redirectURI possibly attached
                                            const url = new URL(window.location.href);
                                            url.pathname = '/resetPassword';

                                            try {
                                                const response = await fetch(`${Model.apiDomain}/kiosk/resetPassword`, {
                                                    method: "POST",
                                                    body: JSON.stringify({
                                                        email: email,
                                                        applicationLink: url.toString()
                                                    }),
                                                    headers: {
                                                        "Content-Type": "application/json"
                                                    }
                                                });
                                                const json = await response.json();
                                                if (!response.ok) throw new Error(json.msg);
                                                // show success message
                                                $(".success-message")
                                                    .text("Email successfully sent. Please follow the link from the email to reset your password.")
                                                    .slideDown(200);
                                            } catch(error) {
                                                let message = error.message;
                                                $('.error-message')
                                                .text(message)
                                                .slideDown(200);
                                                
                                                $('.login .email').val('').focus();
                                            }
                                        })
                                )
                                .append(
                                    $('<div/>')
                                    .addClass('login-btn')
                                    .addClass('btn')
                                    .addClass('black')
                                    .text('Login')
                                    .on('click', function() {
                                        $("body").html(UserView.login());
                                    })
                                )
                            )
                    )
            );
    },
    resetPassword: function() {
        return $('<div/>')
            .addClass('reset-password')
            .addClass('login')
            .append(
                $('<div/>')
                    .addClass('stripe')
                    .append(
                        $('<div/>')
                            .addClass('title')
                            .text(MetaController.getSystemTitle())
                    )
                    .append(
                        $('<div/>')
                            .addClass('fields')
                            .append(
                                $('<p class="error-message">')
                                    .hide()
                            )
                            .append(
                                $('<p class="success-message">')
                                    .hide()
                            )
                            .append(
                                $('<input/>')
                                    .addClass('password')
                                    .attr('type', 'password')
                                    .attr('placeholder', 'New Password')
                            )
                            .append(
                                $('<input/>')
                                    .addClass('password-confirm')
                                    .attr('type', 'password')
                                    .attr('placeholder', 'Confirm New Password')
                                    .on('keyup', function(e) {
                                        if (e.keyCode == 13) {
                                            $('.submit-btn').trigger('click');
                                        }
                                    })
                            )
                            .append(
                                $('<div/>')
                                .addClass("btn-group")
                                .append(
                                    $('<div/>')
                                        .addClass('submit-btn')
                                        .addClass('btn')
                                        .text('Reset Password')
                                        .on('click', async () => {
                                            const password = $('input.password').val();
                                            const passwordConfirm = $('input.password-confirm').val();
                                            const resetToken = MetaController.getUrlParam('token');
                                            try {
                                                const response = await fetch(`${Model.serverDomain}/user/changePassword`, {
                                                    method: "POST",
                                                    body: JSON.stringify({
                                                        password: password,
                                                        oldPassword: passwordConfirm,
                                                        token: resetToken,
                                                        email: decodeURIComponent(MetaController.getUrlParam('email'))
                                                    }),
                                                    headers: {
                                                        "Content-Type": "application/json"
                                                    }
                                                });
                                                if (!response.ok) throw new Error(await response.text());
                                                // show success message
                                                $(".success-message")
                                                    .text("Password successfully reset. Please login.")
                                                    .slideDown(200);
                                            } catch(error) {
                                                let message = error.message;
                                                $('.error-message')
                                                .text(message)
                                                .slideDown(200);
                                                
                                                $('.login .password').val('').focus();
                                            }
                                        })
                                )
                                .append(
                                    $('<div/>')
                                    .addClass('login-btn')
                                    .addClass('btn')
                                    .addClass('black')
                                    .text('Login')
                                    .on('click', function() {
                                        // clear the url of token and email
                                        const url = new URL(window.location.href);
                                        url.searchParams.delete('token');
                                        url.searchParams.delete('email');
                                        url.pathname = "";
                                        window.location.href = url.toString();
                                        $("body").html(UserView.login());
                                    })
                                )
                            )
                    )
            );
    }
}