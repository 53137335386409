import { Model } from './Model';

import { MetaController as Meta } from './controllers/meta';
import { UserController as User, UserView } from './modules/user';
import { DashboardController as Dashboard } from './modules/dashboard';
import { SwitchController as Switch } from './modules/switch';
import { CollectionController as Collection } from './modules/collection';
import { ItemController as Item } from './modules/item';
import { SchemaController as Schema } from './controllers/schema';

export const Controller = {
    init: function () {
        if (window.location.pathname.includes("/logout")) {
            // Logout
            Controller.User.logout();
        } else if (window.location.pathname.includes("/resetPassword") && Controller.Meta.getUrlParam('token') != null) {
            // Reset password
            if (localStorage.token) {
                Controller.User.loadActiveUser(function () {
                    Controller.User.resetPassword(Controller.Meta.getUrlParam('token'));
                });
            } else {
                Controller.User.resetPassword(Controller.Meta.getUrlParam('token'));
            }

        } else if (Controller.Meta.getUrlParam('token') != null) {
            // Login via remote login
            Controller.User.remoteLogin(Controller.Meta.getUrlParam('token'));
        } else if (localStorage.token) {
            // $('body').html(View.Dashboard.temp());
            // Controller.Collection.init('model');
            
            // Load activeUser
            Controller.User.loadActiveUser(function () {
                // if callback query param, then redirect to callback with accessToken as a cookie
                const callback = Controller.Meta.getUrlParam("callback");
                // handle the callback query params and add accessToken when sending back to callback
                if (callback) {
                    const callbackUrl = new URL(decodeURIComponent(callback));
                    callbackUrl.searchParams.append('accessToken', localStorage.token);
                    return window.location.replace(callbackUrl.href);
                }
                Controller.Dashboard.init();
            });
        } else {
            // Show login view
            $('body').html(UserView.login());
            $('.login .email').focus();
        }

        // window.onpopstate = function() {
        //     window.location = document.location;//event.state.url;
        // };
        //
        // var urlPath = window.location.pathname.split('/');
        // console.log('urlPath: ');
        // console.log(urlPath);
        // switch (urlPath[1]) {
        //     case 'remoteLogin':
        //         // console.log('Remote login!');
        //         // var urlParams = new URLSearchParams(window.location.search);
        //         // Controller.User.remoteLogin(urlParams.get('token'));
        //
        //         Controller.User.remoteLogin(Controller.Meta.getUrlParam('token'));
        //
        //         break;
        //
        //     case 'collection':
        //         Controller.Collection.init(urlPath[2]);
        //         break;
        //
        //     default:
        //         Controller.Dashboard.init();
        // }
    },
    Meta,
    User,
    Dashboard,
    Switch,
    Collection,
    Item,
    Schema,
    Files: {
        upload: function (file, options) {
            // var url = Model.serverDomain+'/file/upload';
            // var xhr = new XMLHttpRequest();
            // var fd = new FormData();
            // xhr.open('POST', url, true);
            // xhr.onreadystatechange = function() {
            //     if (xhr.readyState == 4 && xhr.status == 200) {
            //         // Every thing ok, file uploaded
            //         console.log(xhr.responseText); // handle response.
            //     }
            // };
            // fd.append('uploadFile', file);
            // xhr.send(fd);

            var fd = new FormData();
            fd.append('uploadFile', file, file.name);

            if (options.fileId) {
                fd.append('fileId', options.fileId);
            }

            $.ajax({
                type: 'POST',
                url: Model.serverDomain + '/file/upload',
                data: fd,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    if (options.beforeUpload) {
                        options.beforeUpload();
                    }

                    if (localStorage.token) {
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                    }
                },
                xhr: function () {
                    var xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener("progress", function (evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            //Do something with upload progress here
                            console.log('progress: ');
                            console.log(percentComplete);
                        }
                    }, false);

                    // xhr.addEventListener("progress", function(evt) {
                    //     if (evt.lengthComputable) {
                    //         var percentComplete = evt.loaded / evt.total;
                    //         //Do something with download progress
                    //     }
                    // }, false);

                    return xhr;
                },
                success: function (data) {
                    console.log(data);
                    if (options.path) {
                        var fileId;
                        let file;
                        console.log(data);
                        if (data.existingId) {
                            fileId = data.existingId;
                            file = data.result;
                        } else {
                            fileId = data.result.upserted[0]._id;
                            file = data.file
                            file._id = fileId;
                        }

                        console.log(file);

                        console.log('fileId: ' + fileId);

                        Controller.Item.save(options.path, fileId, options.type, function () {
                            console.log('options: ');
                            console.log(options);
                            if (options.afterUpload) {
                                options.afterUpload(fileId, file);
                            }
                        });
                    }

                },
                error: function (err) {
                    console.error(err);
                    
                    if (options.uploadFailed)
                        options.uploadFailed(err);
                }
            });

        },
        delete: function (fileId, options) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: "DELETE",
                    url: Model.serverDomain + '/file/delete',
                    data: { fileId },
                    dataType: "json",
                    beforeSend: function (xhr) {
                        if (options.beforeUpload) {
                            options.beforeUpload();
                        }

                        if (localStorage.token) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token);
                        }
                    },
                    success: (data) => {
                        resolve(data)
                    },
                    error: (err) => {
                        console.error(err.message);
                        reject(err)
                    }
                })
            });
        }
    }
}